<template>

  <div class="grid cartao mt-5 mb-5">
    <div class="cabecalho">
      <label class="texto-matricula">Cartão de Matrícula</label>
    </div>

    <div class="corpo-cartao">

      <div v-if="!aluno.foto" class="foto-perfil">
        <svg width="180" height="180" viewBox="-40 -5 280 222" fill="none" style="background-color: #D9D9D9; border-radius: 20px;" xmlns="http://www.w3.org/2000/svg">
          <path d="M100.881 113.58C132.132 113.58 157.487 88.2246 157.487 56.9731C157.487 25.7217 132.132 0.366699 100.881 0.366699C69.6293 0.366699 44.2743 25.7217 44.2743 56.9731C44.2743 88.2246 69.6293 113.58 100.881 113.58ZM151.198 126.159H129.538C120.811 130.168 111.101 132.448 100.881 132.448C90.6601 132.448 80.9899 130.168 72.2237 126.159H50.5639C22.7717 126.159 0.24707 148.683 0.24707 176.476V182.765C0.24707 193.182 8.69873 201.634 19.1159 201.634H182.646C193.063 201.634 201.514 193.182 201.514 182.765V176.476C201.514 148.683 178.99 126.159 151.198 126.159Z" fill="white"/>
        </svg>
      </div>

      <!-- <div v-else>
        <svg width="170" height="190" viewBox="-9 -5 220 222" fill="none" style="background-color: black; border-radius: 20px;" xmlns="http://www.w3.org/2000/svg">
          <path d="M100.881 113.58C132.132 113.58 157.487 88.2246 157.487 56.9731C157.487 25.7217 132.132 0.366699 100.881 0.366699C69.6293 0.366699 44.2743 25.7217 44.2743 56.9731C44.2743 88.2246 69.6293 113.58 100.881 113.58ZM151.198 126.159H129.538C120.811 130.168 111.101 132.448 100.881 132.448C90.6601 132.448 80.9899 130.168 72.2237 126.159H50.5639C22.7717 126.159 0.24707 148.683 0.24707 176.476V182.765C0.24707 193.182 8.69873 201.634 19.1159 201.634H182.646C193.063 201.634 201.514 193.182 201.514 182.765V176.476C201.514 148.683 178.99 126.159 151.198 126.159Z" fill="white"/>
        </svg>
      </div> -->

      <div class="nome-aluno">
        <label class="">{{ aluno.nome }} {{ aluno.sobrenome }}</label>
      </div>

      <hr style="height:2px;" class="mb-4 mt-2">

      <div class="grid col-12 m-0 p-0 infos-aluno">

        <div class="col-6 m-0 p-0 labels mb-3" >
          <label class="col-12 m-0 p-0">CPF:</label>
          <label class="m-0 p-0 bold">{{ aluno.cpf }}</label>
        </div>

        <div class="col-6 m-0 p-0 labels " >
          <label class="col-12 m-0 p-0">Nascimento:</label>
          <label class="m-0 p-0 bold">{{ aluno.nascimento }}</label>
        </div>

        <div class="col-12 m-0 p-0 labels mb-3" >
          <label class="col-12 m-0 p-0">Matrícula:</label>
          <label class=" m-0 p-0 bold">{{ aluno.matricula }}</label>
        </div>

        <div class="col-12 m-0 p-0 labels mb-3" >
          <label class="col-12 m-0 p-0">Escola:</label>
          <label class=" m-0 p-0 bold">{{ aluno.matricula }}</label>
        </div>

      </div>

      <div class="mb-5" style="color: red; max-height:40px ; ">
        <label class="col-12 m-0 p-0" > Validade: </label>
        <label class="m-0 p-0 bold" style="font-weight: bold;"> 15/12/2024 </label>
      </div>

      <!-- botar a logo aqui -->
      <div class="col-12 m-0 p-0 text-center">
        <label class="texto-secundario"
          style="background-color: orangered; border-radius: 5px;"> Pessoal e intransferível
        </label>
      </div>

      <div class=" logo" style="">
        <img src="" alt="">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "cartao-matricula",

  props: {
    width: { type: String, default: "16rem" },
    color: { type: String, default: "secondary" },
    aluno: {},
  },

  data() {
    return {
    };
  },

  methods: {
  },

};

</script>

<style>

.labels {
  color: #666666;
}

.bold{
  font-weight: bold;
  font-size: 20px;
}

.nome-aluno {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.foto-perfil {
  text-align: center;
  margin: -100px 0px 0px 0px;
  padding: 0px;
}

.logo {
  margin-bottom: 20px;
}

.corpo-cartao {
  padding: 0px 25px 0px 25px;
}

.texto-matricula {
  margin-top: 40px !important;
  font-size: 30px;
}

.texto-secundario {
  padding: 5px 15px 5px 15px;
  color: white;
  font-weight: bold;
}

.cartao {
  border-radius: 15px;
  width: 450px;
  height: 800px;
  background-color: white;
}

.cabecalho {
  height: 170px;
  color: white;
  background-color: #368c96;
  border-radius: 15px 15px 0px 0px;
  text-align: center;
  font-weight: bold;
  width: 100%;
}

</style>
